import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles, createStyles } from '@material-ui/core'
import { URL } from '../../constants/project'

const useStyles = makeStyles(
  createStyles({
    img: {
      width: '100%',
    },
  })
)

const Image = ({ src, ...rest }) => {
  const classes = useStyles()
  
  const imgSrc = /^http/.test(src) ? src : `${URL}${src}`
  
  return (
    <img
      {...rest}
      src={imgSrc}
      alt="Изображение отсутсвует"
      className={classNames(classes.img, rest.className)}
      loading="lazy"
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
}

export default Image
