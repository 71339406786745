import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { URL } from '../constants/project'
import Seo from '../components/Seo/Seo'
import Layout from '../components/Layout/Layout'
import Image from '../components/MarkdownRenderers/Image'
import Heading from '../components/MarkdownRenderers/Heading'
import Paragraph from '../components/MarkdownRenderers/Paragraph'
import Ul from '../components/MarkdownRenderers/Ul'
import Content from '../components/Content/Content'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      '& > div': {
        padding: theme.spacing(5, 0),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(5, 0),
        },

        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(3, 0),
        },
      },

      [theme.breakpoints.down('md')]: {
        width: '100%',
      },

      padding: theme.spacing(5, 0),
      width: '75%',
    },
    headerTitle: {
      fontWeight: 600,
      marginBottom: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: 0
      },
    },
    markdown: {
      '& > h5, h4, h3, h2, h1, p': {
        textAlign: 'center',
      },
    },
    image: {
      maxWidth: 400,
    },
  })
)

export const query = graphql`
  query($id: String) {
    strapiArticle(id: { eq: $id }) {
      Title
      Description
      Text
      ContentBlocks {
        id
        Title
        Description
        Media {
          url
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const classes = useStyles()
  
  const header = (
    <>
      <Seo title={data?.strapiArticle?.Title} />
      <Typography className={classes.headerTitle} align="center" variant="h2">
        {data?.strapiArticle?.Title}
      </Typography>
      <ReactMarkdown
        className={classes.markdown}
        source={data?.strapiArticle?.Description}
        renderers={{
          heading: Heading, 
          image: (props) => <Image {...props} className={classes.image} />, 
          paragraph: Paragraph, 
          list: Ul,
        }}
        escapeHtml={false}
      />
    </>
  )

  return (
    <Layout header={header}>
      <section className={classes.container}>
        {data?.strapiArticle?.Text && (
          <ReactMarkdown
            className={classes.markdown}
            source={data.strapiArticle.Text}
            renderers={{ image: Image, heading: Heading, paragraph: Paragraph, list: Ul }}
            escapeHtml={false}
          />
        )}
        {data?.strapiArticle?.ContentBlocks?.map((value) => (
          <Content
            key={value.id}
            title={value.Title}
            description={value.Description}
            media={value?.Media?.map(value => `${URL}${value.url}`)}
          />
        ))}
      </section>
    </Layout>
  )
}

export default Article
