import React from 'react'
import classNames from 'classnames'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => createStyles({
  ul: {
    ...theme.typography.body1,
  }
}))

const Ul = props => {
  const classes = useStyles()

  console.log(props)

  return props.ordered ? <ol
    {...props}
    tight={props.tight.toString()} 
    className={classNames(classes.ul, props.className)} 
  /> : <ul
    {...props}
    tight={props.tight.toString()} 
    className={classNames(classes.ul, props.className)} 
  />
}

export default Ul
