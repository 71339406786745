import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme =>
  createStyles({
    typography: {
      padding: theme.spacing(2, 0),
      weight: 500,
    },
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    h6: {},
  })
)

const Heading = ({ level, ...rest }) => {
  const classes = useStyles()

  return (
    <Typography
      className={classNames(classes.typography, {
        [classes.h1]: level === 1,
        [classes.h2]: level === 2,
        [classes.h3]: level === 3,
        [classes.h4]: level === 4,
        [classes.h5]: level === 5,
        [classes.h6]: level === 6,
      })}
      variant={`h${level}`}
      {...rest}
    />
  )
}

Heading.propTypes = {
  level: PropTypes.number.isRequired,
}

export default Heading
