import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Heading from '../MarkdownRenderers/Heading'
import Parapgraph from '../MarkdownRenderers/Paragraph'
import Image from '../MarkdownRenderers/Image'
import Ul from '../MarkdownRenderers/Ul'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      maxWidth: '100%',
    },
    item: {
      boxSizing: 'border-box',
      padding: theme.spacing(3),
      display: 'flex !important',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1)
      }
    },
    markdown: {
      '& > h5, h4, h3, h2, h1': {
        textAlign: 'center !important',
      },
      '& > p': {
        [theme.breakpoints.down('sm')]: {
          textAlign: 'justify'
        }
      }
    },
    slider: {
      '& .slick-track': {
        alignItems: 'center',
        display: 'flex',
      },

      '& .slick-prev:before, .slick-next:before': {
        color: '#000000',
        fontSize: theme.typography.h4.fontSize,

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .slick-prev': {
        left: -theme.spacing(5),
      },

      '& .slick-next': {
        right: -theme.spacing(3),
      },

      height: '100%',
      width: '100%',
    },
  })
)

const Content = ({ title, description, media }) => {
  const classes = useStyles()
  
  return (
    <div className={classes.container}>
      <Typography align="center" className={classes.item} variant="h4">
        {title}
      </Typography>
      <ReactMarkdown
        className={classNames(classes.item, classes.markdown)}
        source={description}
        renderers={{ heading: Heading, image: Image, paragraph: Parapgraph, list: Ul }}
        escapeHtml={false}
      />
      {media.length === 1 && (
        <div className={classes.item}>
          <Image className={classes.image} src={media[0]} />
        </div>
      )}
      {media.length >= 2 && (
        <Slider
          className={classes.slider}
          dots={true}
          infinite={true}
          speed={500}
          slidesToScroll={1}
          slidesToShow={1}
        >
          {media.map(value => (
            <div key={value} className={classes.item}>
              <Image className={classes.image} src={value} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  )
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(PropTypes.string),
}

export default Content
